(function() {
    angular.module('EntrakV5').controller('selectFloorController', selectFloorController);

    function selectFloorController($scope, $rootScope, Service, Api, KEY, $state) {
        console.log('selectFloorController');

        var caller = Api.createApiCaller();
        $rootScope.title = Service.translate("location.title");

        $scope.compName = null;
        $scope.floors = [];

        $scope.externalTenantId = null;
        $scope.showConsentBtn = false;


    /* node navigation */
        //first load
        $rootScope.getTenantId().then(function(tenantId){
            $rootScope.loadingPage = 1;
            caller.call(Api.getFloorList(tenantId)).then(function(res){
                $scope.compName = res.name;
                $scope.floors = $rootScope.getFilteredZoneList(res.zones);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        });

        $scope.selectFloor = function(f){
            if ($rootScope.currentState === 'old_selectFloor'){
                $state.go("old_location", { floorId: f.id });
            } else if ($rootScope.currentState === 'selectHeatmap'){
                $state.go("heatmap", { floorId: f.id });
            }
        }
    /* node navigation */

        $scope.$on('$destroy', function() {
            console.log("selectFloorController destroy");
            caller.cancel();
        });
    }
})();
